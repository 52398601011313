<template>
  <div class="admin-login" v-if="school == 'uu'">
    <div class="main-card" style="display: flex; align-items: center; justify-content: space-between">
      <div>
        <img style="width: 440px; margin: 80px 0 0 120px" :src="logo" alt="" />
      </div>
      <div>
        <div class="form-box">
          <div style="font-size: 18px; margin-bottom: 20px">登入</div>
          <el-input v-model="form.username" placeholder="请输入账号"></el-input>
          <div style="width: 10px; margin: 30px 0"></div>
          <el-input v-model="form.password" show-password placeholder="请输入密码"></el-input>
          <div style="width: 10px; margin: 30px 0"></div>
          <div style="display: flex; align-items: center">
            <el-input v-model="form.captcha" placeholder="请输入验证码"></el-input>
            <div style="
                width: 140px;
                border: 1px solid #eee;
                height: 40px;
                margin-left: 10px;
                padding: 4px;
              ">
              <img style="height: 100%" :src="codeImg" alt="" @click="getCode" />
            </div>
          </div>
          <div class="login-btn" @click="login">
            <i v-if="show" class="el-icon-loading"></i>
            登入
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { adminLogin, getVerificationCode } from "@/api/admin.js";
  import { login } from "@/api/eco.js";
  import { baseInfo } from "@/api/common.js";


  import { setAAuth, setAuth, setSAuth, setBAuth, setSchool, getSchool } from "@/utils/auth.js";
  export default {
    data() {
      return {
        logo: require("@/assets/img/admin/logo.png"),
        form: {
          username: "",
          password: "",
          captcha: "",
        },
        show: false,
        codeImg: "",
        school: getSchool()
      };
    },
    created() {
      // this.school = window.location.host.split(".")[0];
      if (this.school == 'uu') {
        this.getCode();
      } else {
        if (this.$route.query.code) {
          login({
            code: this.$route.query.code,
          }).then((res) => {
            if (res.code == 1) {
              // 设置主，买，卖凭证
              setAuth(res.data.token);
              setSAuth(res.data.token);
              setBAuth(res.data.token);
              setSchool(res.data.school);
              localStorage.USERINFO = res.data.account;
              // 设置管理后台凭证
              setAAuth(res.data.teacher_token);
              localStorage.ADMIN_USERNAME = res.data.username;
              let _this = this;
              // setTimeout(() => {
              // _this.getBaseInfo()
              // }, 2000);



              if (res.data.url) {
                this.setMenu(res.data.url)
                this.$router.replace(res.data.url);

              } else {
                this.$router.replace("admin");
              }

            }
          });
        }
      }
    },
    mounted() {
      this.keyDown();
    },
    beforeDestroy() {
      document.onkeydown = null;
    },
    methods: {
      getBaseInfo() {
        baseInfo().then((res) => {
          if (res.code == 1) {
            this.$store.state.baseInfo = res.data;
            // 更改网站标题和图标
            if (this.$route.name == "fecebook") {
              document.title = "FaceBook";
            } else {
              this.$store.state.title = res.data.site_name

            }
            window.onload = function () {
              var link =
                document.querySelector("link[rel*='icon']") ||
                document.createElement("link");
              link.type = "image/x-icon";
              link.rel = "shortcut icon";
              link.href = res.data.favicon;
              document.getElementsByTagName("head")[0].appendChild(link);
            };
            sessionStorage.BACKURL = res.data.back_url
          }
          if (res.code == 6000) {
            this.$router.replace("error");
          }
        });
      },
      login() {
        let _this = this;
        this.show = true;
        adminLogin(this.form).then((res) => {
          if (res.code == 1) {
            this.getBaseInfo()
            setTimeout(function () {
              setAAuth(res.data.token);
              localStorage.ADMIN_USERNAME = res.data.username;
              _this.$router.push("admin");
              _this.$message.success(res.message);
              _this.show = false;
            }, 1000);
          } else {
            _this.show = false;
          }
        });
      },
      getCode() {
        getVerificationCode().then((res) => {
          if (res.code == 1) {
            this.codeImg = res.data.image;
          }
        });
      },
      keyDown() {
        let _this = this;
        document.onkeydown = (e) => {
          if (e.keyCode == 13) {
            _this.login();
          }
        };
      },

      setMenu(url) {
        switch (url) {
          case '/myAddress'://基本信息设置
            this.$store.state.openMenu = ['myAddress']
            this.$store.state.path = 'myAddress'
            break;
          case '/storeIntroduce'://店铺介绍
            this.$store.state.openMenu = ['storeIntroduce']
            this.$store.state.path = 'storeIntroduce'
            break;
          case '/storeDecorate'://店铺装修
            this.$store.state.openMenu = ['storeIntroduce']

            this.$store.state.path = 'storeDecorate'
            break;
          case '/logisticsSet'://物流设置
            this.$store.state.openMenu = ['meLogistics']
            this.$store.state.path = 'logisticsSet'
            break;
          case '/bankAccount'://收款设置
            this.$store.state.openMenu = ['myIncome']
            this.$store.state.path = 'logisticsSet'
            break;
          case '/globalGoods'://商品优化
            this.$store.state.openMenu = ['myGoods']
            this.$store.state.path = 'globalGoods'
            break;
          case '/marketingCenter'://营销中心
            this.$store.state.openMenu = ['myAddress']
            this.$store.state.path = 'marketingCenter'
            break;
          case '/myGoods'://我的商品
            this.$store.state.openMenu = ['myGoods']
            this.$store.state.path = 'myGoods'
            break;
          case '/meLogistics'://物流订单
            this.$store.state.openMenu = ['meLogistics']

            this.$store.state.path = 'meLogistics'
            break;
        }
      }
    },
  };
</script>
<style lang="less" scoped>
  .admin-login {
    width: 100%;
    height: 580px;
    background: url("../../../assets/img/admin/bj.png") no-repeat;
    background-size: 100% 100%;

    .form-box {
      width: 360px;
      border-radius: 6px;
      padding: 20px;
      background: #fff;
      margin-right: 100px;
      margin-top: 70px;

      .login-btn {
        width: 100%;
        height: 40px;
        line-height: 40px;
        background: linear-gradient(90deg, #ff6533, #f5402d);
        border-radius: 2px;
        text-align: center;
        font-size: 14px;
        color: #fff;
        margin-top: 30px;
        cursor: pointer;
      }

      .login-btn:hover {
        opacity: 0.9;
      }
    }
  }
</style>